import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Layout } from '@containers/Layout/layout';

import './style.scss';

const TermsandConditionsContainer = () => (
  <Layout>
    <Grid container xs={12} classes={{ root: 'termsandConditionsContainers' }}>
      <Typography variant="h5" classes={{ root: 'termsandConditionsContainers__title' }}>
        TÉRMINOS Y CONDICIONES DE USO DE LA PLATAFORMA VIRTUAL DE COMPRAS DE SUPERFUDS S.A.S
      </Typography>
      <section className="termsandConditionsContainers__mainContent">
        <p>
          Las presentes Condiciones de uso (&ldquo;Condiciones&rdquo;) regulan el acceso y uso que
          usted haga, como persona natural o jur&iacute;dica desde cualquier pa&iacute;s del mundo
          de aplicaciones, p&aacute;ginas web, contenido, productos y servicios (los
          &ldquo;Servicios&rdquo; y el &ldquo;Usuario&rdquo; respectivamente) puestos a
          disposici&oacute;n por Superfuds S.A.S., una sociedad comercial constituida bajo las leyes
          colombianas en la C&aacute;mara de Comercio de Bogot&aacute; D.C identificada con NIT
          900.703.907-7, quien para los efectos de los presentes t&eacute;rminos se
          denominar&aacute; &ldquo;SUPERFUDS&rdquo;.&nbsp;
        </p>

        <p>
          Este documento contiene informaci&oacute;n legal que todo usuario debe leer completamente
          en conjunto con la Pol&iacute;tica de Privacidad. Por medio de la aprobaci&oacute;n de los
          presentes T&eacute;rminos y Condiciones, se entiende que el Usuario los ha le&iacute;do y
          aceptado, en todas sus partes, y entiende que estos le son legalmente vinculantes y
          obligatorios. Por tanto, acepta las condiciones de utilizaci&oacute;n y aprovechamiento de
          la Plataforma, Contenido y los Servicios. En caso contrario, el Usuario deber&aacute;
          abstenerse de acceder a la Plataforma y Servicios, ya sea directa o indirectamente, y de
          utilizar cualquier informaci&oacute;n o servicio provisto por la misma.
        </p>

        <p>
          PRIMERO. Naturaleza Jur&iacute;dica: Los presentes t&eacute;rminos y condiciones regulan
          la relaci&oacute;n contractual de car&aacute;cter comercial que une a los Usuarios que
          acceden a la plataforma virtual de SUPERFUDS y/o cualquier otro de sus canales y la forma
          en que habr&aacute; por regularse cualquier adquisici&oacute;n de bienes y servicios a
          trav&eacute;s de estas.&nbsp;
        </p>

        <p>SEGUNDO. Definiciones</p>
        <p>
          Comercio Electr&oacute;nico: Comprende el env&iacute;o, transmisi&oacute;n,
          recepci&oacute;n, almacenamiento de mensajes de datos por v&iacute;a electr&oacute;nica.
          Las dudas que surjan respecto de la eficacia y validez de los mensajes de datos y
          dem&aacute;s actividades vinculadas al comercio electr&oacute;nico se interpretar&aacute;n
          de conformidad con la Ley 527 de 1999.
        </p>

        <p>
          Usuario: Toda persona natural o jur&iacute;dica que, use la Plataforma con el fin de
          adquirir los Productos. Estos se deber&aacute;n encontrar en el territorio nacional o,
          estando en el extranjero, realicen compras para ser entregada en el territorio colombiano
          pagando de la forma habilitada en la Plataforma.
        </p>

        <p>
          Orden de compra: Aquel acuerdo de voluntades celebrado entre el Usuario y SUPERFUDS, por
          medio del cual el Usuario solicita, a trav&eacute;s de la Plataforma, la gesti&oacute;n de
          la compra de productos disponibles en La Plataforma
        </p>

        <p>
          Cookies: Cadenas de texto enviadas virtualmente que son almacenadas por el uso de la
          Plataforma por SUPERFUDS para la consulta de las actividades y preferencias de los
          usuarios.
        </p>

        <p>
          Datos personales: Es toda informaci&oacute;n que permite identificar o hacer identificable
          a una persona f&iacute;sica.
        </p>
        <p>
          Dropshipping: es el modelo mediante el cual la Plataforma act&uacute;a &uacute;nicamente
          como mecanismo de conexi&oacute;n entre el consumidor final y el proveedor de los
          productos. Siendo el Proveedor quien directamente se encarga del almacenamiento y despacho
          de los Productos.&nbsp;
        </p>

        <p>
          Interacci&oacute;n en la Plataforma: Facultad de acceso por parte de los Usuarios para
          conocer los Productos vendidos y exhibidos por SUPERFUDS, la publicidad puesta a
          disposici&oacute;n en la Plataforma y la manifestaci&oacute;n de su voluntad de solicitar
          una orden de compra.
        </p>

        <p>Mayor de edad: Persona natural mayor de dieciocho (18) a&ntilde;os.</p>

        <p>
          Mensajes de datos: La informaci&oacute;n generada, enviada, recibida, almacenada o
          comunicada por medios electr&oacute;nicos, &oacute;pticos o similares, como pudieran ser,
          entre otros, el Intercambio Electr&oacute;nico de Datos (EDI), Internet, el correo
          electr&oacute;nico, el telegrama, el t&eacute;lex o el telefax (Ley 527 de 1999 art. 2
          lit. a).
        </p>

        <p>
          Mensajes de texto (SMS): Todas las comunicaciones y/o notificaciones enviadas por
          SUPERFUDS por medio de mensaje de texto (SMS), WhatsApp y cualquier otro medio de acceso
          al usuario. Se entienden como Mensajes de Texto (SMS) aquellos que sean compartidos por
          los canales autorizados de SUPERFUDS, canales sobre los cuales tiene control. SUPERFUDS no
          ser&aacute; responsable por los mensajes que terceros sin autorizaci&oacute;n puedan
          remitir en su nombre y/o por canales no autorizados.&nbsp;
        </p>

        <p>
          Operador de la Plataforma: Encargado de administrar operativa y funcionalmente la
          Plataforma, que para este caso estar&aacute; representado por SUPERFUDS, o por la persona
          natural o jur&iacute;dica que &eacute;sta designe.
        </p>

        <p>
          Pasarela de pagos: Servicio que permite la realizaci&oacute;n de pagos por parte de los
          Usuarios directamente a SUPERFUDS, a trav&eacute;s de medios electr&oacute;nicos
          utilizando plataformas tecnol&oacute;gicas.
        </p>

        <p>
          Plataforma: Aplicativo web y m&oacute;vil administrado por SUPERFUDS, que permite que
          Usuarios puedan por medio de ordenes de compra adquirir los Productos disponibles en el
          aplicativo web y m&oacute;vil. Versiones de esta Plataforma se pueden descargar en
          aplicaciones de Apple (App Store) y de Google (Google Play). La Autorizaci&oacute;n de uso
          de la Plataforma en ning&uacute;n caso otorga derechos de propiedad intelectual sobre la
          misma a favor de los Usuarios o terceros.&nbsp;
        </p>

        <p>
          Producto(s): significa los art&iacute;culos y productos que se encuentran disponibles para
          ser adquiridos por los Usuarios en la Plataforma.
        </p>

        <p>
          Publicidad: Es toda forma de comunicaci&oacute;n realizada por SUPERFUDS, con el fin de
          brindar informaci&oacute;n sobre productos, actividades comerciales y comunicar
          estrategias o campa&ntilde;as publicitarias o de mercadeo, propias o de terceros;
          realizada como mecanismo de referencia y no como oferta p&uacute;blica.
        </p>

        <p>
          Servicio: significa todos los servicios ofrecidos por medio de la Plataforma a los cuales
          los Usuarios pueden acceder por medio de la Plataforma y sus Equipos.
        </p>

        <p>
          SUPERFUDS: significa SUPERFUDS S.A.S. sociedad comercial colombiana la cual mediante
          medios tecnol&oacute;gicos y de servicio facilita a los Usuarios un cat&aacute;logo
          digital de Productos ofrecidos para ser adquiridos y enviados a la direcci&oacute;n
          solicitada por el Usuario dentro del territorio Colombiano.
        </p>

        <p>
          T&eacute;rminos y condiciones de uso de la Plataforma: Constituyen los t&eacute;rminos que
          han de regular el uso que los Usuarios dan a la Plataforma, as&iacute; como las relaciones
          contractuales que se pueden generar entre el Usuario y SUPERFUDS los cuales ser&aacute;n
          de cumplimiento obligatorio para las partes y se entender&aacute;n aceptados por los
          Usuarios al utilizar la Plataforma y adquirir Productos a trav&eacute;s de ella.&nbsp;
        </p>

        <p>
          Ventanas emergentes (Pop-Ups): Ventana o aviso de internet que emerge
          autom&aacute;ticamente en cualquier momento cuando se utiliza la Plataforma.&nbsp;
        </p>

        <p>
          TERCERO. Objeto: Los presentes t&eacute;rminos y condiciones regulan la
          autorizaci&oacute;n de uso que otorga SUPERFUDS a los Usuarios, para que &eacute;stos
          ingresen y usen la Plataforma y, a trav&eacute;s de ella adquieran los Productos y
          Servicios ofrecidos por SUPERFUDS. Estos t&eacute;rminos y condiciones son de obligatorio
          cumplimiento para los Usuarios y SUPERFUDS. &nbsp;
        </p>

        <p>
          SUPERFUDS a trav&eacute;s de la Plataforma realiza las siguientes acciones: i) exhibe los
          Productos ii) comercializa los Productos a los Usuarios iii) permite el uso de la
          Plataforma por los usuarios. iv) Permite el pago de los Productos mediante la
          conexi&oacute;n de la Plataforma con una plataforma de pagos v) sirve de medio de
          env&iacute;o de comunicaciones a los Usuarios.
        </p>

        <p>
          CUARTO. Uso de la Plataforma: Los Usuarios podr&aacute;n utilizar la Plataforma
          exclusivamente para su uso personal o empresarial, &uacute;nicamente con el fin de
          adquirir Productos o servicios en ella ofertados. En ning&uacute;n caso se podr&aacute;
          entender que la autorizaci&oacute;n de uso de la Plataforma implica el otorgamiento de una
          licencia de la tecnolog&iacute;a de la plataforma de ning&uacute;n tipo.
        </p>

        <p>
          QUINTO. Modificaci&oacute;n: SUPERFUDS podr&aacute; modificar aut&oacute;nomamente y en
          cualquier momento en aspectos formales, procedimentales o sustanciales los presentes
          T&eacute;rminos y Condiciones de uso de la Plataforma, los cuales ser&aacute;n
          actualizados y puestos a disposici&oacute;n de los Usuarios, siendo la &uacute;ltima
          versi&oacute;n publicada la que regular&aacute; las relaciones comerciales que se generen
          al momento de realizarse la transacci&oacute;n. As&iacute; mismo, cuenta con plena
          autonom&iacute;a para modificar los usos de la Plataforma permitidos a los Usuarios, con
          el &uacute;nico deber de informarlo por un medio virtual que permita su
          comunicaci&oacute;n al p&uacute;blico.
        </p>

        <p>
          SEXTO. Servicio al Usuario: El uso de la Plataforma lo realiza el Usuario como persona
          capaz, manifestando que, para la celebraci&oacute;n de contratos de compraventa con
          SUPERFUDS, cuenta con plena capacidad legal para ser sujeto de derechos y obligaciones,
          calidades que acredita al momento de generar su registro. Para ello, el Usuario tiene la
          obligaci&oacute;n de proveer un documento de identidad nacional (C&eacute;dula de
          Ciudadan&iacute;a/C&eacute;dula de Extranjer&iacute;a/ o Pasaporte) expedido por la
          Registradur&iacute;a Nacional del Estado Civil de Colombia o por la autoridad competente
          en su pa&iacute;s de origen que certifique que es mayor de edad.&nbsp;
        </p>

        <p>
          S&Eacute;PTIMO. Cuenta de Usuario: los Usuarios deber&aacute;n crear una cuenta para poder
          adquirir los Productos y Servicios de la p&aacute;gina, en adelante &ldquo;Cuenta de
          Usuario&rdquo;. Para su creaci&oacute;n se solicitar&aacute;n datos como nombre, fecha de
          nacimiento, tel&eacute;fono, direcci&oacute;n, correo electr&oacute;nico y c&eacute;dula
          de ciudadan&iacute;a y se solicitar&aacute; la autorizaci&oacute;n para el tratamiento de
          los datos personales de los Usuarios de acuerdo con la pol&iacute;tica de privacidad de
          SUPERFUDS. Se encuentra prohibido tener m&aacute;s de una (1) cuenta relacionada a
          cualquiera de los datos anteriormente mencionados. Esta informaci&oacute;n se utiliza para
          la plena identificaci&oacute;n de las Usuarios que adquieran los Productos, para el
          cumplimiento de los presentes t&eacute;rminos y condiciones, para la prevenci&oacute;n de
          fraudes, para vincular al Usuario con SUPERFUDS, para fines de publicidad y mercadeo y en
          general para los fines definidos en el ac&aacute;pite manejo de informaci&oacute;n.
        </p>

        <p>
          Podr&aacute;n los Usuarios, adem&aacute;s de la informaci&oacute;n obligatoria y
          facultativa requerida al momento de la creaci&oacute;n de la cuenta, suministrar
          voluntariamente m&aacute;s datos relacionados con su individualizaci&oacute;n al momento
          en que cree su propio Perfil dentro de La Plataforma.
        </p>

        <p>
          El uso de las cuentas es personal e intransferible, por lo cual los Usuarios no se
          encuentran facultados para ceder los datos de validaci&oacute;n para el acceso a la
          Plataforma ni el uso de su cuenta a ning&uacute;n tipo de terceros. El incumplimiento de
          lo anterior, acarrear&aacute; la suspensi&oacute;n y bloqueo definitivo de la cuenta. En
          caso de olvido de los datos de validaci&oacute;n o de usurpaci&oacute;n de &eacute;stos,
          es obligaci&oacute;n del Usuario informar a SUPERFUDS a trav&eacute;s de la opci&oacute;n
          &ldquo;recuperar contrase&ntilde;a&rdquo; o a trav&eacute;s de comunicaci&oacute;n enviada
          al correo electr&oacute;nico{' '}
          <a href="mailto:experiencia@superfuds.com.co">experiencia@superfuds.com.co </a>.&nbsp;
        </p>

        <p>
          Las cuentas de los Usuarios ser&aacute;n administradas por SUPERFUDS o por la persona que
          &eacute;ste designe, teniendo plena facultad para la conservaci&oacute;n o no de la
          cuenta, cuando la informaci&oacute;n suministrada por los Usuarios no sea veraz, completa
          o segura; o cuando se presente incumplimiento de las obligaciones de los Usuarios. En
          ning&uacute;n momento SUPERFUDS solicitar&aacute; al Usuario informaci&oacute;n que no
          resulte necesaria para su vinculaci&oacute;n con el y/o para la facilitaci&oacute;n del
          pago. Por lo tanto, los datos de tarjetas d&eacute;bito o cr&eacute;dito, solo se
          solicitar&aacute;n al momento de realizar el pago virtual si ese es el m&eacute;todo de
          pago deseado por el Usuario. Dichos datos de pago ser&aacute;n solicitados directamente en
          la Plataforma de Pago en donde SUPERFUDS no tendr&aacute; ning&uacute;n tipo de acceso a
          la informaci&oacute;n y/o validaci&oacute;n diferente al comprobante de pago,
          condici&oacute;n que los Usuarios reconocen. Por lo anterior, en ning&uacute;n caso
          SUPERFUDS conocer&aacute; informaci&oacute;n relacionada con el medio de pago utilizado
          por el Usuario y por lo tanto no tendr&aacute; ning&uacute;n tipo de responsabilidad sobre
          el uso de dicha Plataforma de Pago pero si el deber de colaborar con el Usuario cuando se
          presente alg&uacute;n tipo de fraude o suplantaci&oacute;n del Usuario en dicha plataforma
          de pago utilizando la Plataforma de SUPERFUDS y/o se presente alguna inconsistencia en el
          proceso de pago de los Productos de SUPERFUDS.
        </p>
        <p>
          Con la creaci&oacute;n de la Cuenta de Usuario, los Usuarios est&aacute;n manifestando su
          voluntad de aceptaci&oacute;n expresa e inequ&iacute;voca de los presentes T&eacute;rminos
          y Condiciones, as&iacute; como de la Pol&iacute;tica de tratamiento de datos personales y
          privacidad de SUPERFUDS. El Usuario autoriza expresamente al momento de la
          aceptaci&oacute;n de los presentes T&eacute;rminos, el uso de Cookies por parte de
          SUPERFUDS en toda actividad de uso que realice de la Plataforma.
        </p>

        <p>
          OCTAVO. Capacidad: En virtud de las condiciones de capacidad legal establecidas en el
          C&oacute;digo Civil Colombiano y de la validez de la manifestaci&oacute;n de voluntad a
          trav&eacute;s de medios electr&oacute;nicos establecida en la Ley 527 de 1999, los
          Usuarios al momento de la creaci&oacute;n de la Cuenta de Usuario, manifiestan
          expresamente tener capacidad para celebrar el tipo de transacciones que se pueden realizar
          usando la Plataforma; y con base en lo prescrito en la Ley 1098 de 2006 de la
          Rep&uacute;blica de Colombia los menores de edad cuentan con capacidad para celebrar
          &eacute;ste tipo de transacciones, no obstante SUPERFUDS deber&aacute;: i) Excluir del
          sistema de informaci&oacute;n los datos de menores de edad que hayan utilizado la
          Plataforma; ii) Dar a conocer a las autoridades de cualquier situaci&oacute;n, de la que
          tenga conocimiento, que ponga en peligro la integridad de un menor de edad; iii) Informar
          a los menores que se encuentren interesados en adquirir productos a trav&eacute;s de la
          Plataforma usando medios de pago electr&oacute;nico, que deber&aacute;n realizar la
          transacci&oacute;n econ&oacute;mica de car&aacute;cter electr&oacute;nico a trav&eacute;s
          de sus padres o representantes legales, previo registro en la plataforma por parte de
          &eacute;stos.
        </p>

        <p>
          NOVENO. Proceso de creaci&oacute;n de Ordenes de Compra: SUPERFUDS exhibe a trav&eacute;s
          de la Plataforma Productos y Servicios para el conocimiento y ofertamiento al Usuario.
          Esta exhibici&oacute;n de Productos sirve como referencia a los Usuarios para seleccionar
          los que les interesas y efectuar una compra, usando la Plataforma como medio para celebrar
          el contrato de compraventa.&nbsp;
        </p>
        <p>
          Para el proceso de efectuar la Orden de Compra los Usuarios deben estar plenamente
          identificados en su Cuenta de Usuario y seguir el siguiente procedimiento:
        </p>
        <p>
          a) Ingresar a la Plataforma especificando la ciudad o municipio de entrega para determinar
          los productos que se encuentran disponibles en esa &aacute;rea geogr&aacute;fica.
        </p>
        <p>
          b) Seleccionar el lugar de entrega. Se debe suministrar por el Usuario la direcci&oacute;n
          exacta donde se realizar&aacute; la entrega de los Productos. Esta direcci&oacute;n debe
          encontrarse en el rango de cobertura de entrega, en caso de no encontrarse en dicho rango
          no se permitir&aacute; la finalizaci&oacute;n de la transacci&oacute;n. En caso de que por
          error se permita la transacci&oacute;n, SUPERFUDS, a su juicio podr&aacute; remitir los
          Productos y asumir el sobrecosto del env&iacute;o o devolver el dinero al Usuario
          informando el error y la imposibilidad de entrega, en este caso, el usuario deber&aacute;
          informar el mecanismo de devoluci&oacute;n del dinero y SUPERFUDS proceder con dicha
          devoluci&oacute;n dentro de los treinta (30) d&iacute;as siguientes.&nbsp;
        </p>
        <p>
          c) Seleccionar el producto. Una vez seleccionado se pone a disposici&oacute;n del Usuario
          las caracter&iacute;sticas y valor total del producto por medio de fotograf&iacute;a y
          notas de referencias, que permiten la plena individualizaci&oacute;n del producto para el
          an&aacute;lisis detallado del Usuario.
        </p>
        <p>
          d) Validaci&oacute;n del producto. Cumplido el paso anterior, SUPERFUDS deber&aacute;
          exhibir al consumidor un resumen del producto en cuanto a sus condiciones generales tales
          como la marca y la presentaci&oacute;n suministrada. De esta manera el Usuario
          podr&aacute; validar y confirmar la informaci&oacute;n y el producto seleccionado.
        </p>
        <p>
          e) Ingreso del producto al carrito de compras. SUPERFUDS tiene total autonom&iacute;a para
          limitar el ingreso de productos al carrito de compras con ocasi&oacute;n a la cantidad.
        </p>
        <p>
          f) Valor. Una vez se han agotados los pasos precedentes, se pone a disposici&oacute;n del
          Usuario el valor a pagar por la compra de los productos seleccionados., Dicho valor
          refleja: i) remuneraci&oacute;n por el servicio de domicilio; ii) valor total de los
          productos adquiridos, incluyendo costos de transacci&oacute;n e impuestos.&nbsp;
        </p>
        <p>
          g) Pago. La selecci&oacute;n del bot&oacute;n &ldquo;Pagar&rdquo; corresponde a la
          voluntad inequ&iacute;voca del Usuario de adquirir un producto que se encuentra en el
          carrito de compras ya que se ha informado con suficiencia las caracter&iacute;sticas de
          este, los costos de env&iacute;o y dem&aacute;s caracter&iacute;sticas necesarias para
          perfeccionar la venta. El Consumidor realizar&aacute; el pago directamente en las
          plataformas virtuales de pagos que tiene contratadas SUPERFUDS para este fin.&nbsp;
        </p>
        <p>
          h) Forma de pago. El Usuario debe seleccionar el medio de pago que desea utilizar, y que
          est&eacute; disponible en la Plataforma de acuerdo a lo se&ntilde;alado en estos
          T&eacute;rminos y Condiciones. En el evento de seleccionarse el pago electr&oacute;nico,
          El Usuario tendr&aacute; la obligaci&oacute;n y ser&aacute; el &uacute;nico responsable de
          verificar cada tarjeta de cr&eacute;dito/d&eacute;bito que registre en su cuenta de la
          plataforma de pago conforme al procedimiento estipulado y disponible en esta. SUPERFUDS no
          tendr&aacute; ning&uacute;n tipo de injerencia, responsabilidad u conocimiento sobre la
          informaci&oacute;n de la plataforma de pago, su procedimiento, requerimientos de
          informaci&oacute;n u cualquier otro. Lo anterior teniendo en cuenta la distinci&oacute;n
          absoluta entre la Plataforma y la plataforma de pagos. &nbsp;
        </p>
        <p>
          i) Resumen y Correo electr&oacute;nico. Una vez completados los pasos anteriores y
          verificado el pago y/o medio de pago a utilizar, se le exhibe a trav&eacute;s de una
          ventana emergente al Usuario un resumen detallado de la transacci&oacute;n y el
          n&uacute;mero de la Orden de Compra. Esta informaci&oacute;n ser&aacute; remitida al
          correo electr&oacute;nico registrado por el Usuario.
        </p>
        <p>
          j) Entrega. Verificados todos los datos correspondientes a la transacci&oacute;n, la
          cobertura de entrega de Productos y el pago, se entregar&aacute;n los Productos adquiridos
          al aliado log&iacute;stico para que &eacute;ste entregue en la direcci&oacute;n indicada
          por el Usuario. &nbsp;En caso de no poderse realizar la entrega por razones imputables al
          Usuario, deber&aacute; SUPERFUDS dejar constancia de este hecho.
        </p>

        <p>
          DECIMO. Cobertura del servicio. A la fecha SUPERFUDS cuenta con cobertura en todas las
          ciudades principales de Colombia y aquellas secundarias en donde sus aliados
          log&iacute;sticos presenten servicios. Sin embargo, la cobertura especifica dentro de las
          ciudades puede variar seg&uacute;n el alcance log&iacute;stico con el que cuente SUPERFUDS
          y sus aliados de log&iacute;stica para realizar las respectivas entregas de las compras
          realizadas por sus Usuarios.
        </p>

        <p>
          SUPERFUDS est&aacute; en la facultad de modificar de forma unilateral y en cualquier
          momento estas coberturas. En todo Caso, est&aacute; en la obligaci&oacute;n de ofrecer al
          Usuario el mecanismo para verificar si la direcci&oacute;n donde desea recibir su pedido
          se encuentra dentro de la cobertura. Verificaci&oacute;n que se debe hacer antes del pago
          de los Productos. Para esto el Usuario deber&aacute; ingresar la direcci&oacute;n donde
          recibir&aacute; el Pedido en la Plataforma verificando que la geogr&aacute;fica sea la
          correcta, el tiempo de entrega ser&aacute; notificado al Usuario durante el proceso de
          compra y antes de realizar el pago, lo anterior dependiendo de los Productos seleccionados
          y su ubicaci&oacute;n..
        </p>

        <p>
          D&Eacute;CIMO PRIMERO: Horarios de entrega y costos de domicilios. La hora de entrega de
          una Orden de Compra depender&aacute; de la franja horaria seleccionada por los Usuarios en
          el momento del Check Out. Esta corresponde a la hora en que el aliado log&iacute;stico
          entregar&aacute; el producto en la direcci&oacute;n indicada. La entrega de los Productos
          tendr&aacute; un costo indicado como costo de domicilio, el cual var&iacute;a de acuerdo a
          ubicaci&oacute;n geogr&aacute;fica seleccionada y los Beneficios (Promociones y
          Descuentos) que apliquen sobre las entregas a domicilio. La cantidad de entregas por
          &aacute;rea geogr&aacute;fica posee una capacidad limitada por lo cual SUPERFUDS se
          reserva el derecho de eliminar temporalmente la disponibilidad de entrega en un
          &aacute;rea determinada cuando esta ha llegado a su l&iacute;mite de capacidad, el Usuario
          ver&aacute; en la Plataforma las &aacute;reas geogr&aacute;ficas disponibles. SUPERFUDS no
          realiza de forma directa la entrega de los Pedidos, este cuenta con aliados
          log&iacute;sticos de la m&aacute;s alta calidad y experiencia para realizar los mismos.
          SUPERFUDS emitir&aacute; una gu&iacute;a de entrega que ser&aacute; remitida al Usuario
          para que &eacute;ste haga el seguimiento de su pedido y/o solicite informaci&oacute;n
          directamente ante el aliado log&iacute;stico. En caso de que en la ciudad de entrega
          ocurran incidentes fuera de control de SUPERFUDS y/o el aliado log&iacute;stico, sin que
          necesariamente tengan que ser calificadas como causa extra&ntilde;a como por ejemplo, sin
          limitarse a ellos, marchas, huelgas, condiciones clim&aacute;ticas adversas o accidentes,
          se har&aacute; todo lo posible por entregar la orden con el menor tiempo de retraso
          posible, sin que se pueda establecer como cierta la hora o d&iacute;a de entrega, pero
          notificando tal hecho al Usuario cuando haya un retraso relevante.&nbsp;
        </p>

        <p>
          D&Eacute;CIMO SEGUNDO: Disponibilidad de productos. Los Productos presentados en la
          Plataforma son revisados respecto a los inventarios, la Plataforma cuenta con un
          desarrollo que impide la adquisici&oacute;n de los Productos que no se encuentren
          disponibles en inventario y/o mostrar&aacute; un aviso de &ldquo;agotado&rdquo; o similar
          sobre este Producto con el fin de informar el hecho al Usuario, cualquiera de las dos
          opciones ser&aacute; v&aacute;lida como forma de brindar informaci&oacute;n al Usuario. En
          los casos anteriores, la Plataforma no dejar&aacute; que se incluya en la orden de compra
          y en el valor a pagar el valor de Producto agotado. En el evento que se cobre un Producto
          que no se encuentre disponible para el despacho, SUPERFUDS puede: i) Cumplir con el pedido
          excluyendo el Producto o Productos solicitados agotados, caso en el cual se
          descontar&aacute; su valor del valor total del pedido; ii) Comunicarse con el Usuario para
          concertar el cumplimiento con un producto sustituto en caso de que este lo dese de esta
          forma, en caso de negativa del Usuario, proceder&aacute; con la devoluci&oacute;n del
          dinero. En caso de que se seleccione la devoluci&oacute;n del dinero correspondiente al
          Producto agotado se proceder&aacute; con el mismo en el t&eacute;rmino de treinta (30)
          d&iacute;as calendario. La devoluci&oacute;n se podr&aacute; realizar al medio de pago
          utilizado por el Usuario o por el medio de pago acordado con el Usuario siempre y cuando,
          sea a trav&eacute;s del sistema financiero y haya confirmaci&oacute;n de pago al momento
          de hacerlo.&nbsp;
        </p>

        <p>
          DECIMO TERCERO: Pedido m&iacute;nimo. Las compras de Productos que se hagan a
          trav&eacute;s de la plataforma deber&aacute;n tener un valor m&iacute;nimo que ser&aacute;
          informado de forma clara y amplia en los canales de e commerce y que podr&aacute; ser
          modificada de forma unilateral por SUPERFUDS en cualquier momento. En todo caso, el valor
          del pedido m&iacute;nimo no incluir&aacute; el valor de env&iacute;o o domicilio. La
          plataforma no tramitar&aacute; pedidos con un valor inferior al antes se&ntilde;alado y
          arrojar&aacute; un mensaje en donde se informe este hecho al Usuario.&nbsp;
        </p>

        <p>
          DECIMO CUARTO: Precio de venta. El precio que se deber&aacute; pagar por cada
          art&iacute;culo adquirido ser&aacute; el precio vigente en el sitio al momento de hacerse
          el pedido, en el evento en que el Producto tenga dos precios diferentes, se
          aplicar&aacute; el menor valor del producto, el valor con descuento o el precio informado
          sin tachaduras o otras formas que se utilicen para mostrar un descuento o
          promoci&oacute;n. SUPERFUDS no ser&aacute; responsable ni deber&aacute; respetar el valor
          de los precios irrisorios cuando por error se publiquen de esta forma. Se entienden por
          precios irrisorios aquellos que evidentemente resultan desproporcionados, comparativamente
          con el precio del producto en el mercado. La exigencia de productos a precios irrisorios
          por parte del Usuario se entiende como mala fe y enriquecimiento sin causa por lo tanto no
          tendr&aacute; que ser respetado. En estos casos, SUPERFUDS podr&aacute; cancelar la(s)
          ordenes realizadas de productos con dichos precios, a su libre discreci&oacute;n y con
          plena autonom&iacute;a o, a su elecci&oacute;n, informar el error y el precio real del
          producto con el fin de que el Usuario cancele la diferencia o solicite la
          devoluci&oacute;n del dinero.&nbsp;
        </p>

        <p>
          DECIMO QUINTO: medios de pago. Los usuarios podr&aacute;n utilizar los siguientes
          mecanismos para hacer el pago de sus productos: pago tarjeta de cr&eacute;dito, PSE y pago
          en puntos de recaudo (todo es por la misma pasarela de pagos) y el bot&oacute;n de
          Rappi.&nbsp;
        </p>

        <p>
          DECIMO SEXTO. Entrega de orden de compra. El aliado log&iacute;stico realizar&aacute; la
          entrega de la de los Productos adquiridos mediante la Orden de Compra en la
          direcci&oacute;n indicada por el Usuario para tal fin, realizar&aacute; la entrega de los
          Productos verificando el estado de la Entrega junto al Usuario, en caso de no encontrarse
          el Usuario en la direcci&oacute;n dejar&aacute; el pedido con un tercero autorizado para
          tal fin.
        </p>

        <p>
          D&Eacute;CIMO S&Eacute;PTIMO. Obligaciones del Usuario. Con la aceptaci&oacute;n de los
          presentes T&eacute;rminos y Condiciones El Usuario se obliga a lo siguiente en
          adici&oacute;n a los dem&aacute;s que se puedan encontrar en el presente documento: 1)
          Suministrar informaci&oacute;n veraz y fidedigna al momento de crear su Cuenta de Usuario;
          2) Abstenerse de transferir a terceros los datos de validaci&oacute;n (nombre de usuario y
          contrase&ntilde;a); 3) Abstenerse de utilizar la Plataforma para realizar actos contrarios
          a la moral, la ley, el orden p&uacute;blico y buenas costumbres o competencia desleal en
          contra SUPERFUDS, los Mandatarios o de terceros; 4) Pagar oportunamente la
          contraprestaci&oacute;n econ&oacute;mica definida en el contrato de compraventa; 5)
          Informar inmediatamente a SUPERFUDS en caso de olvido o usurpaci&oacute;n de los datos de
          validaci&oacute;n; 6) Abstenerse de realizar conductas atentatorias del funcionamiento de
          la Plataforma; 7) Abstenerse de suplantar la identidad de otros Usuarios; 8) Abstenerse de
          descifrar, descompilar o desensamblar o realizar ingenier&iacute;a inversa de cualquier
          elemento de la Plataforma o sus partes; 9) Habilitar la utilizaci&oacute;n de ventanas
          emergentes durante la operaci&oacute;n; 10) En general todas aquellas conductas necesarias
          para la ejecuci&oacute;n del negocio jur&iacute;dico, como i) la recepci&oacute;n de los
          Productos adquiridos, ii) exhibir la identificaci&oacute;n en caso de venta de productos
          de uso restringido, iii) verificar al momento de la validaci&oacute;n que los productos
          seleccionados s&iacute; corresponden a los necesitados, iv) informarse sobre las
          instrucciones de uso, contenido y consumo de los productos.
        </p>

        <p>
          D&Eacute;CIMO OCTAVO. Deberes de SUPERFUDS. En virtud de los presentes T&eacute;rminos y
          Condiciones y en adici&oacute;n a los dem&aacute;s que se puedan encontrar en el presente
          documento, SUPERFUDS, se obliga a 1) Suministrar informaci&oacute;n cierta, fidedigna,
          suficiente, clara y actualizada respecto de los Productos y Servicios que exhibe; 2)
          Indicar las caracter&iacute;sticas generales del producto para que sirvan de referencia a
          los Usuario, para el efecto son marca y presentaci&oacute;n; 3) Informar suficientemente
          sobre los medios habilitados para que los Usuarios realicen el pago; 4) Enviar al correo
          electr&oacute;nico suministrado por el Usuario resumen de la compra y constancia de la
          transacci&oacute;n; 5) Poner a disposici&oacute;n de los Usuarios los t&eacute;rminos y
          condiciones de uso de la Plataforma de forma actualizada; (5) Utilizar la
          informaci&oacute;n &uacute;nicamente para los fines establecidos en los presentes
          t&eacute;rminos; 7) Utilizar mecanismos de informaci&oacute;n y validaci&oacute;n durante
          la transacci&oacute;n como ventanas emergentes (Pop Ups), que permitan al Usuario aceptar
          o no cada paso del proceso de compra.
        </p>

        <p>
          D&Eacute;CIMO NOVENO. Descripci&oacute;n de los Productos y servicios. Todos los Productos
          y Servicios comercializados por la Plataforma cuentan con una descripci&oacute;n general;
          esta descripci&oacute;n se realiza por SUPERFUDS de forma amplia y suficiente para
          informar a los Usuarios sobre las caracter&iacute;sticas y contenidos de &eacute;stos.
          Esta informaci&oacute;n se realiza a partir de la informaci&oacute;n que los productores
          de los Productos o Servicios entregan a SUPERFUDS &nbsp;por lo tanto, cualquier
          inconsistencia frente al Producto y la informaci&oacute;n brindada se debe dar a conocer a
          SUPERFUDS para su correcci&oacute;n y, cualquier acci&oacute;n legal respecto a una
          diferencia entre la informaci&oacute;n brindada y el Producto se deber&aacute; entablar de
          forma directa contra el proveedor del producto, en caso que el Usuario, en uso de los
          derechos que como consumidor le corresponden entable esta reclamaci&oacute;n contra
          SUPERFUDS este vincular&aacute; al proceso directamente al proveedor quien tiene la
          obligaci&oacute;n legal de mantenerle indemne. SUPERFUDS publicar&aacute; la
          informaci&oacute;n cumpliendo con todas las pr&aacute;cticas legales de
          visualizaci&oacute;n, que en todo caso dependen del dispositivo en donde el Usuario
          observe el producto. SUPERFUDS se reserva el derecho de actualizar, modificar y/o
          descontinuar los productos exhibidos en La Plataforma.
        </p>

        <p>
          VIG&Eacute;SIMO. Garant&iacute;as: Salvo informaci&oacute;n en contrario establecida en
          cada uno de los Productos o servicios la garant&iacute;a de los Productos y Servicios
          ser&aacute; aquella que les es exigible por ley &ldquo;garant&iacute;a legal&rdquo; y no
          ninguna otra, estas por defectos o vicios de los Productos o Servicios (relacionadas con
          calidad, idoneidad, seguridad, buen estado o funcionamiento del Producto). Para solicitar
          la garant&iacute;a el Usuario deber&aacute; escribir al correo electr&oacute;nico&nbsp;
          <a href="mailto:experiencia@superfuds.com.co">experiencia@superfuds.com.co </a> con el fin
          de que se le informe, para el producto espec&iacute;fico cual es la garant&iacute;a
          aplicable, el t&eacute;rmino de &eacute;sta y su aplicaci&oacute;n para el da&ntilde;o o
          defecto que presente el Producto o servicio adquirido por el Usuario. En todo caso, el
          plazo de la garant&iacute;a empezar&aacute; a regir desde su entrega al Usuario. En todo
          caso se le solicitar&aacute; constancia al Usuario de que el producto fue adquirido en la
          Plataforma como la factura o el n&uacute;mero de la orden de compra. Para realizar el
          proceso de devoluci&oacute;n por garant&iacute;a el Usuario no deber&aacute; conservar
          nada diferente a la garant&iacute;a y el comprobante de compra a SUPERFUDS. SUPERFUDS
          tendr&aacute; los derechos establecidos en la ley para responder ante una garant&iacute;a,
          es decir, reparar, en los casos que aplique o reemplazar el Producto por uno
          id&eacute;ntico o devolver el precio pagado por este, sin incluir otros valores diferentes
          al propio del Producto objeto de garant&iacute;a, lo anterior de acuerdo al
          art&iacute;culo 11 y siguientes de la ley 1480 de 2011. El Usuario reconoce que la
          Plataforma es un Marketplace en donde diferentes proveedores tienen sus productos y que la
          garant&iacute;a final del mismo no ser&aacute; brindada por SUPERFUDS sino por la marca
          productora de dichos productos a trav&eacute;s de sus canales autorizados. En el evento
          del inicio de una reclamaci&oacute;n por garant&iacute;a de productos de marcas diferentes
          a las propias de SUPERFUDS remitir&aacute; directamente al Usuario a los canales de
          atenci&oacute;n para reclamaci&oacute;n y garant&iacute;as de &eacute;stas y
          cooperar&aacute; con el Usuario en el proceso de reclamaci&oacute;n que corresponda. Al
          utilizar la Plataforma el Usuario entiende y acepta lo anterior, por lo cual
          acudir&aacute; directamente al reclamo de la garant&iacute;a a quien efectivamente tiene
          la capacidad de brid&aacute;rsela, es decir, el fabricante o distribuidor autorizado en el
          territorio nacional. Lo anterior siempre y cuando SUERFUDS haya brindado el soporte
          esperado para el proceso de reclamaci&oacute;n y cooperado con el Usuario en el
          mismo.&nbsp;
        </p>

        <p>
          VIG&Eacute;SIMO PRIMERO. DROPSHIPPING: Las categor&iacute;as de Electrodom&eacute;sticos,
          vestuario, utensilios para hacer ejercicio opera bajo la modalidad de Dropshipping en
          donde el proveedor ser&aacute; quien directamente realiza el almacenamiento y despacho de
          los Productos adquiridos. En este sentido SUPERFUDS, despu&eacute;s de ejecutar la compra
          &uacute;nicamente tendr&aacute; la obligaci&oacute;n de mantener informado al usuario
          sobre el estado del despacho y velar por que los productos entregados sean aquellos
          ofrecidos en la Plataforma, as&iacute; como velar por el cumplimiento de las dem&aacute;s
          garant&iacute;a y obligaciones establecidas en estos t&eacute;rminos y condiciones. El
          Usuario reconoce que SUPERFUDS no tiene injerencia en el despacho, tiempos y
          garant&iacute;a de estos Productos pero que, en todo caso cooperar&aacute; con &eacute;ste
          para la respuesta satisfactoria por parte de los proveedores para el cumplimiento de sus
          obligaciones legales y contractuales. &nbsp;
        </p>
        <p>
          VIGESIMO SEGUNDO. Reclamaciones y atenci&oacute;n de PQR. SUPERFUDS recibir&aacute; y
          dar&aacute; tr&aacute;mite a las solicitudes, peticiones, quejas y reclamos que formule el
          Usuario en las eventualidades que se desprendan del servicio que presta la
          compa&ntilde;&iacute;a como cumplimiento en los tiempos de entrega y calidad de los
          productos entre otros casos.
        </p>

        <p>
          Si el Usuario tiene alguna duda respecto de los T&eacute;rminos y Condiciones,
          Pol&iacute;tica de Privacidad, uso de la Plataforma o de su Perfil, podr&aacute; ponerse
          en contacto con SUPERFUDS escribiendo al correo electr&oacute;nico&nbsp;
          <a href="mailto:experiencia@superfuds.com.co">experiencia@superfuds.com.co </a> y/o al
          n&uacute;mero de WhastApp que est&eacute; publicado en la p&aacute;gina web. Los mensajes
          ser&aacute;n atendidos en un m&aacute;ximo de 48 horas h&aacute;biles y resueltos
          definitivamente en un tiempo de cinco d&iacute;as (15) seg&uacute;n lo establecido por la
          legislaci&oacute;n vigente y por las pol&iacute;ticas internas de SUPERFUDS para la
          atenci&oacute;n de PQR.
        </p>

        <p>
          El Usuario podr&aacute; realizar reclamaciones cuando el Producto entregado no corresponda
          al producto indicado, cuando el Producto no se encuentre en el estado indicado en la
          Plataforma o no se haya entregado un Producto que se encontraba en la Orden de Compra,
          realizarla a trav&eacute;s del correo electr&oacute;nico&nbsp;
          <a href="mailto:experiencia@superfuds.com.co">experiencia@superfuds.com.co </a>. Y el
          WhatsApp +57 322 683 2493 &nbsp;En estos casos SUPERFUDS revisar&aacute; dichas
          reclamaciones dentro de las 48 horas h&aacute;biles siguientes y de aplicar,
          proceder&aacute; con la soluci&oacute;n que corresponda bien sea, la remisi&oacute;n del
          Producto faltante, el reemplazo del producto remitido en mal estado o la devoluci&oacute;n
          del dinero pagado por el Usuario por la adquisici&oacute;n del Producto.&nbsp;
        </p>

        <p>
          VIG&Eacute;SIMO TERCERO Solicitud de Devoluciones. El Usuario podr&aacute; solicitar
          devoluciones del dinero o cambios de Producto de acuerdo a la tipolog&iacute;a del
          Producto siempre garantizando que los productos conserven las caracter&iacute;sticas
          originales, est&eacute;n en el estado en que fueron entregados, en los empaques o
          embalajes en los que fueron entregados y no hayan sido usados o consumidos. Los alimentos
          frescos o congelados no podr&aacute;n ser devueltos salvo por temas de garant&iacute;as.
          &nbsp;Se podr&aacute;n entonces solicitar y tramitar devoluciones Productos no perecederos
          y los dem&aacute;s productos que la ley otorgue este derecho siguiendo este procedimiento:
          i) Cambios m&aacute;ximo dentro de los treinta (30) d&iacute;as calendario siguientes a la
          recepci&oacute;n del Producto. ii) Los productos deben conservar las
          caracter&iacute;sticas originales. iii) Para solicitar cambios y devoluciones los Usuarios
          deben presentar una solicitud escrita al correo electr&oacute;nico&nbsp;
          <a href="mailto:experiencia@superfuds.com.co">experiencia@superfuds.com.co </a> y
          diligenciar el formulario o brindar la informaci&oacute;n que por este medio se les
          solicite.&nbsp;
        </p>

        <p>
          Productos perecederos: Se recibir&aacute;n solicitudes de cambio m&aacute;ximo 24horas
          despu&eacute;s de recibido el Producto. estas devoluciones solo proceder&aacute;n por mal
          estado del producto o por haberse entregado un producto diferente al solicitado en la
          Orden de Compra.&nbsp;&nbsp; &nbsp; &nbsp;Esto siguiendo el mismo procedimiento y
          requerimientos se&ntilde;alados en el inciso anterior. En el momento de la recogida del
          producto el Repartidor verificar&aacute; el estado de los Productos, garantizando que los
          Productos que requieren cadena de fr&iacute;o no la hayan perdido. Para todos los cambios
          se debe realizar la solicitud por escrito a trav&eacute;s del correo electr&oacute;nico de
          servicio al cliente&nbsp;
          <a href="mailto:experiencia@superfuds.com.co">experiencia@superfuds.com.co </a>&nbsp;
          remitiendo la informaci&oacute;n relacionada con el producto, el motivo de la
          devoluci&oacute;n, en n&uacute;mero de la orden de compra o factura y cualquier
          informaci&oacute;n adicional solicitada por SUPERFUDS durante el tr&aacute;mite.&nbsp;
        </p>

        <p>
          Para las reclamaciones y solicitud de devoluciones, despu&eacute;s de la revisi&oacute;n y
          de la verificaci&oacute;n por parte de servicio al cliente se podr&aacute; realizar
          devoluci&oacute;n del dinero al cliente abon&aacute;ndolo como saldo a favor,
          consignaci&oacute;n directa a la cuenta, o realizando la entrega del Producto sustituto
          elegido por el Usuario y la recogida del Producto sujeto a devoluci&oacute;n, lo anterior
          a costo del usuario, salvo por problemas de calidad o estado del Producto. &nbsp;Esto, de
          acuerdo con las facultades que la ley otorgue para cada caso de devoluci&oacute;n y la
          voluntad de SUPERFUDS.&nbsp;
        </p>

        <p>
          VIG&Eacute;SIMO CUARTO. Contenidos. A trav&eacute;s de la Plataforma SUPERFUDS
          podr&aacute; poner a disposici&oacute;n de los Usuarios informaci&oacute;n de
          car&aacute;cter comercial y publicitario, propio o de terceros de conformidad a las buenas
          costumbres comerciales.
        </p>

        <p>
          Toda la informaci&oacute;n puesta a disposici&oacute;n en la Plataforma como
          im&aacute;genes, publicidad, nombres, marcas, lemas y dem&aacute;s elementos de propiedad
          intelectual; son utilizados leg&iacute;timamente por SUPERFUDS, sea porque son de su
          propiedad, porque tiene autorizaci&oacute;n para ponerlos a disposici&oacute;n, o porque
          se encuentra facultado para hacerlo en virtud de las decisiones 351 de 1993 y 486 de 2000
          de la Comunidad Andina de Naciones y la ley 23 de 1982.
        </p>

        <p>
          VIG&Eacute;SIMO QUINTO. Funcionamiento de la Plataforma. SUPERFUDS administra directamente
          o por medio de terceras personas la Plataforma, toda la informaci&oacute;n que se comunica
          all&iacute; corresponde a informaci&oacute;n cierta y actualizada. En ning&uacute;n caso
          responder&aacute; por da&ntilde;os directos o indirectos que sufra el Usuario por la
          utilizaci&oacute;n o incapacidad de utilizaci&oacute;n de la Plataforma. La Plataforma se
          encuentra disponible las 24 horas del d&iacute;a para su acceso y consulta. Para la
          realizaci&oacute;n de transacciones la disponibilidad de la Plataforma es de 24 horas al
          d&iacute;a, sin embargo todo pedido realizado posterior a las 10:00 am quedar&aacute;
          asignado como si se hubiese realizado el d&iacute;a posterior y por tanto no estar&aacute;
          habilitado para ser entregado en algunas franjas del d&iacute;a siguiente, es decir, si el
          pedido se realiza el d&iacute;a 1 a las 10:00 am, es como si se hubiese realizado en el
          d&iacute;a 2, por lo cual la entrega quedar&aacute; montada el d&iacute;a 2 y los tiempos
          de entrega de se deber&aacute;n contar desde esta fecha.
        </p>

        <p>
          SUPERFUDS realiza los mejores esfuerzos para mantener en operaci&oacute;n la Plataforma,
          pero en ning&uacute;n caso garantiza disponibilidad y continuidad permanente de la
          Plataforma.
        </p>

        <p>
          SUPERFUDS se reserva el derecho de cancelar las cuentas de Usuarios y/o de prohibir el
          acceso a la Plataforma a los Usuarios que realicen conductas violatorias de los presentes
          t&eacute;rminos y condiciones o que incumplan las obligaciones contra&iacute;das.
        </p>

        <p>
          VIG&Eacute;SIMO SEXTO. Derecho de retracto. SUPERFUDS es un marketplace en l&iacute;nea,
          con su propio centro de distribuci&oacute;n, y a cargo log&iacute;sticamente de la
          calidad, estado y condiciones de varios de sus Productos. El derecho de retracto aplica
          solo sobre algunos de los productos comercializados por SUPERFUDS. Lo anterior de acuerdo
          al art&iacute;culo 47 de la ley 1480 de 2011
        </p>
        <p>
          A los productos alimenticios, que tienen la calidad de perecederos, o que est&aacute;n
          destinados al uso personal, no les ser&aacute; aplicable el derecho de retracto
          establecido en el art&iacute;culo 47 de la Ley 1480 de 2011. SUPERFUDS se ci&ntilde;e
          expresamente a la siguiente regulaci&oacute;n legal respecto al derecho de retracto:
        </p>

        <p>
          En todos los contratos para la venta de bienes y prestaci&oacute;n de servicios mediante
          sistemas de financiaci&oacute;n otorgada por el productor o proveedor, venta de tiempos
          compartidos o ventas que utilizan m&eacute;todos no tradicionales o a distancia, que por
          su naturaleza no deban consumirse o no hayan comenzado a ejecutarse antes de cinco (5)
          d&iacute;as, se entender&aacute; pactado el derecho de retracto por parte del consumidor
          En el evento en que se haga uso de la facultad de retracto, se resolver&aacute; el
          contrato y se deber&aacute; reintegrar el dinero que el consumidor hubiese pagado. El
          consumidor deber&aacute; devolver el producto al productor o proveedor por los mismos
          medios y en las mismas condiciones en que lo recibi&oacute;. Los costos de transporte y
          los dem&aacute;s que conlleve la devoluci&oacute;n del bien ser&aacute;n cubiertos por el
          consumidor. El t&eacute;rmino m&aacute;ximo para ejercer el derecho de retracto
          ser&aacute; de cinco (5) d&iacute;as h&aacute;biles contados a partir de la entrega del
          bien o de la celebraci&oacute;n del contrato en caso de la prestaci&oacute;n de servicios.
          Se except&uacute;an del derecho de retracto, los siguientes casos: 1. En los contratos de
          prestaci&oacute;n de servicios cuya prestaci&oacute;n haya comenzado con el acuerdo del
          consumidor; 2. En los contratos de suministro de bienes o servicios cuyo precio
          est&eacute; sujeto a fluctuaciones de coeficientes del mercado financiero que el productor
          no pueda controlar; 3. En los contratos de suministro de bienes confeccionados conforme a
          las especificaciones del consumidor o claramente personalizados; 4. En los contratos de
          suministro de bienes que, por su naturaleza, no puedan ser devueltos o puedan deteriorarse
          o caducar con rapidez; 5. En los contratos de servicios de apuestas y loter&iacute;as; 6.
          En los contratos de adquisici&oacute;n de bienes perecederos; 7. En los contratos de
          adquisici&oacute;n de bienes de uso personal. El proveedor deber&aacute; devolverle en
          dinero al consumidor todas las sumas pagadas sin que proceda a hacer descuentos o
          retenciones por concepto alguno. En todo caso la devoluci&oacute;n del dinero al
          consumidor no podr&aacute; exceder de treinta (30) d&iacute;as calendario desde el momento
          en que ejerci&oacute; el derecho.
        </p>

        <p>
          PAR&Aacute;GRAFO: REVERSION DE PAGOS: Las reversiones de compras online son posibles
          cuando: esta fue por objeto de un Fraude, Fue una operaci&oacute;n no solicitada, &nbsp;El
          producto adquirido no sea recibido, El producto entregado no corresponda a lo solicitado,
          no cumpla con las caracter&iacute;sticas inherentes o las atribuidas por la
          informaci&oacute;n que se suministre sobre &eacute;l, El producto se encuentre defectuoso.
        </p>
        <p>
          Para las reversiones SUPERFUDS contar&aacute; con 15 d&iacute;as h&aacute;biles a partir
          de momento en que se reciban los documentos necesarios que apliquen para cada caso y
          &eacute;stos sean aprobados. Las solicitudes de reversi&oacute;n deber&aacute;n ser
          solicitadas dentro de los cinco (5) d&iacute;as h&aacute;biles siguientes a la fecha en
          que el consumidor tuvo noticia la operaci&oacute;n fraudulenta o no solicitada, o en que
          debi&oacute; haber recibido producto o lo recibi&oacute; defectuoso o sin que
          correspondiera a lo solicitado. Lo anterior de acuerdo al art&iacute;culo 51 del estatuto
          del consumidor que podr&aacute;s encontrar en el siguiente link&nbsp;
          <a
            href="http://www.secretariasenado.gov.co/senado/basedoc/ley_1480_2011.html"
            target="_blank"
            rel="noopener nofollow noreferrer"
          >
            http://www.secretariasenado.gov.co/senado/basedoc/ley_1480_2011.html
          </a>
          y al decreto 587 de 2016 al cual podr&aacute;s acceder a trav&eacute;s de este link:&nbsp;
          <a
            href="https://www.alcaldiabogota.gov.co/sisjur/normas/Norma1.jsp?i=65906"
            target="_blank"
            rel="noopener nofollow noreferrer"
          >
            https://www.alcaldiabogota.gov.co/sisjur/normas/Norma1.jsp?i=65906
          </a>
          &nbsp;
        </p>

        <p>
          Para estos casos debes comunicarte por alguno de los mecanismos de contacto de servicio al
          cliente de la sociedad, los cuales podr&aacute;s encontrar f&aacute;cilmente en estos
          t&eacute;rminos y condiciones o en la parte de debajo de nuestra p&aacute;gina web y
          remitir la siguiente informaci&oacute;n: 1) &nbsp; Fundamentos de la solicitud de
          reversi&oacute;n (el cliente debe explicar el porqu&eacute; de su solicitud, de forma
          clara, precisa, oportuna y veraz).las causas podr&aacute;n ser algunas de las mencionadas
          en el primer inciso de este par&aacute;grafo. 2) &nbsp;Valor por el que se solicita la
          reversi&oacute;n. 3) para poder hacer las validaciones internas, es necesario que nos
          env&iacute;es la copia de la transacci&oacute;n en donde se pueda ver n&uacute;mero,
          fecha, hora y valor. &nbsp;4) debes enviarnos copia del documento de identidad del
          propietario del medio de pago con el cual se efectu&oacute; la compra. 5) Adjuntar
          certificaci&oacute;n de la cuenta bancaria con la cual se realiz&oacute; la
          operaci&oacute;n. No se admiten extractos o copias vencidas. Si requieres apoyo te puedes
          comunicar con nuestras l&iacute;neas de atenci&oacute;n de WhastApp y/o por correo
          electr&oacute;nico. &nbsp;
        </p>

        <p>
          VIG&Eacute;SIMO S&Eacute;PTIMO. Costo de env&iacute;o por devoluci&oacute;n, derecho de
          retracto o cambio y reembolsos. El costo del transporte del producto para ejercicio de
          derecho de retracto, devoluci&oacute;n o cambio ser&aacute; asumido por el Usuario.
          SUPERFUDS asumir&aacute; el costo del env&iacute;o para devoluci&oacute;n o cambio solo
          cuando el motivo del retracto, devoluci&oacute;n o cambio es por una causa de la cual es
          responsable SUPERFUDS y/o la marca. En este caso, SUPERFUDS recoge el Producto a devolver,
          una vez lo recoja y valid&eacute; la informaci&oacute;n, enviar&aacute; el producto
          adecuado o har&aacute; el reembolso del dinero si el Usuario lo escoge as&iacute;. Algunos
          de estos casos son: Error en despacho, retardo en la entrega del producto,
          recepci&oacute;n del producto con da&ntilde;os o vicios debido a asuntos de almacenamiento
          o despacho, entre otros.
        </p>

        <p>
          SUPERFUDS contar&aacute; con al menos 15 d&iacute;as naturales para recibir y procesar tu
          devoluci&oacute;n desde el momento en que se procese la devoluci&oacute;n, de ah&iacute;
          la&nbsp;&nbsp; &nbsp; &nbsp;Entidad Bancaria puede tomarse hasta 25 d&iacute;as en
          procesar y hacer efectivo la devoluci&oacute;n del&nbsp;&nbsp; &nbsp; &nbsp;dinero. En
          total el reembolso puede tardarse hasta 59 d&iacute;as naturales desde el inicio del
          proceso a trav&eacute;s de los canales autorizados de contacto. Una vez recibidos y
          procesados los Productos se remitir&aacute; una notificaci&oacute;n por e-mail sobre este
          proceso y se proceder&aacute; al reembolso del valor total de los productos. El reembolso
          se realizar&aacute; utilizando la misma forma de pago que la compra original.
        </p>

        <p>
          VIG&Eacute;SIMO OCTAVO. Comercio electr&oacute;nico. En cumplimiento de las disposiciones
          colombianas sobre mensajes de datos seg&uacute;n la ley 527 de 1999, se comunica que la
          legislaci&oacute;n nacional reconoce validez a los mensajes de datos y por tanto ellos
          adquieren car&aacute;cter y entidad probatoria. En consecuencia, entienden los Usuarios,
          que mediante el cruce de mensajes de datos los intervinientes pueden dar lugar al
          nacimiento, modificaci&oacute;n y extinci&oacute;n de obligaciones, siendo de su resorte
          exclusivo el contenido, consecuencias, responsabilidades y efectos de la
          informaci&oacute;n generada.
        </p>

        <p>
          La transacci&oacute;n comercial que nace por este medio entre Usuarios y SUPERFUDS, es la
          celebraci&oacute;n de un contrato de compraventa por medios electr&oacute;nicos, el cual
          se describe en la ventana emergente que acepta el Usuario al momento de la
          celebraci&oacute;n del negocio jur&iacute;dico y en ning&uacute;n momento se configura
          relaci&oacute;n contractual diferente.
        </p>

        <p>
          VIG&Eacute;SIMO NOVENA. Manejo de informaci&oacute;n. La informaci&oacute;n recolectada
          por SUPERFUDS para ejecutar la compraventa, es suministrada por los Usuarios de forma
          libre y voluntaria, para que esta sea administrada por SUPERFUDS o por quien &eacute;ste
          designe para el cumplimiento de los deberes adquiridos, lo que implica su
          recolecci&oacute;n; almacenamiento en servidores o repositorios de SUPERFUDS o de
          terceros; circulaci&oacute;n de los mismos dentro de la organizaci&oacute;n de SUPERFUDS;
          comunicaci&oacute;n a los Usuarios informaci&oacute;n comercial, publicitaria y de
          mercadeo relacionada con su actividad comercial.
        </p>

        <p>
          As&iacute; mismo, los datos recolectados ser&aacute;n objeto de an&aacute;lisis para fines
          de mejorar la estrategia de negocios del portal web, apoyada en herramientas de
          inteligencia de negocios y miner&iacute;a de datos, que permiten adquirir conocimientos
          prospectivos para fines de predicci&oacute;n, clasificaci&oacute;n y segmentaci&oacute;n.
        </p>

        <p>
          El Usuario podr&aacute; ejercer su derecho de conocer, actualizar, modificar y suprimir
          los datos personales existentes en las bases de datos asociadas a la Plataforma. Para ello
          deber&aacute; realizar la solicitud de consulta, reclamo o supresi&oacute;n a la
          direcci&oacute;n electr&oacute;nica&nbsp;
          <a href="mailto:experiencia@superfuds.com.co">experiencia@superfuds.com.co </a> detallando
          las modificaciones a realizar y aportando los documentos que lo soportan.
        </p>

        <p>
          SUPERFUDS es responsable del tratamiento de la informaci&oacute;n personal recolectada a
          trav&eacute;s del portal web, responsabilidad que podr&aacute; delegar en un tercero, en
          calidad de responsable o encargado de la informaci&oacute;n, asegurando contractualmente
          adecuado tratamiento de la misma.
        </p>

        <p>
          TRIGESIMO. Beneficios: Promociones y Descuentos. A continuaci&oacute;n, se detallan los
          t&eacute;rminos y condiciones que se aplicar&aacute;n a todos los beneficios (descuentos y
          promociones) que se anuncian o a las que se puede acceder a trav&eacute;s de la
          Plataforma. Lo anterior salvo que de forma expresa se establezcan t&eacute;rminos y
          condiciones particulares para una promoci&oacute;n o descuento especifico y se publiquen
          los mismos en la Plataforma. En todos caso, los que a continuaci&oacute;n se establecen
          aplicar&aacute;n ante dudas o vac&iacute;os de los espec&iacute;ficos de una
          actividad.&nbsp;
        </p>
        <p>
          Los descuentos corresponden al beneficio u oferta que SUPERFUDS por s&iacute; mismo y/o
          con aliados aplica al valor de la Orden de Compra o sobre una parte de &eacute;sta, este
          valor se indica en el resumen de la orden de pago en la secci&oacute;n
          &ldquo;Descuentos&rdquo; y disminuye el valor a pagar por parte del Usuario.
        </p>

        <p>
          Las promociones corresponder&aacute;n al beneficio u oferta que SUPERFUDS por s&iacute;
          mismo y/o con aliados aplica al precio de venta de los Productos ofrecidos en la
          Plataforma.
        </p>
        <p>
          El precio en promoci&oacute;n de los art&iacute;culos corresponder&aacute; al valor que no
          se encuentra tachado, el valor tachado corresponde al valor del Producto antes de la
          promoci&oacute;n. En caso de que el valor tachado sea inferior al valor no tachado se
          entender&aacute; por el valor de promoci&oacute;n el valor menor.
        </p>

        <p>
          Los descuentos aplicar&aacute;n sobre el valor de la Orden de Compra o sobre los Productos
          de la Orden de Compra comprendidos en el descuento. Si en una Orden de Compra a la cual se
          aplica un descuento se incluyen Productos en promoci&oacute;n, el descuento
          aplicar&aacute; sobre el precio indicado en promoci&oacute;n a no ser que en el descuento
          se indique lo contrario.
        </p>

        <p>
          Vigencia de los beneficios (descuentos y promociones): los beneficios presentados dentro
          de los banners de la Plataforma o que se env&iacute;en en comunicaciones propias o con
          aliados, tienen la vigencia indicada en los mismos, en caso de no indicarse estar&aacute;n
          vigentes por el d&iacute;a en el que se visualizan. La vigencia tambi&eacute;n se
          encuentra supeditada a las existencias disponibles para el beneficio.
        </p>

        <p>
          Los beneficios ser&aacute;n efectivos y est&aacute;n supeditados a las coberturas
          anunciadas, el Usuario hace el pedido y programa la entrega dentro de las fechas de
          vigencia de los beneficios o hasta agotar existencias (teniendo en cuenta las unidades
          disponibles de Productos destinadas a la promoci&oacute;n).
        </p>

        <p>
          El Usuario podr&aacute; hacer compras de uno o varios &iacute;tems en promoci&oacute;n
          teniendo en cuenta para un &iacute;tem la cantidad m&aacute;xima de unidades que se pueden
          incluir en una Orden de Compra indicada en la Plataforma. Para los casos donde el
          &iacute;tem no indique directamente la cantidad m&aacute;xima, se tendr&aacute; como
          capacidad m&aacute;xima de un &iacute;tem los que el sistema deje incluir bajo el precio
          de promoci&oacute;n en la Orden de Compra.
        </p>

        <p>
          SUPERFUDS podr&aacute; finalizar un beneficio antes de tiempo dado el caso en que los
          art&iacute;culos de dicha promoci&oacute;n se encuentren agotados en inventario o
          sucursales de sus aliados.
        </p>
        <p>
          SUPERFUDS podr&aacute; restringir la compra de un mismo &iacute;tem o Producto con
          promoci&oacute;n a un tiempo determinado (Ej. Un &iacute;tem en promoci&oacute;n cada 5
          d&iacute;as) por Usuario o dispositivo.
        </p>

        <p>
          Si un pedido contiene art&iacute;culos en promoci&oacute;n, y al momento de realizar todo
          el proceso log&iacute;stico interno SUPERFUDS evidencia que el art&iacute;culo est&aacute;
          agotado en existencias, SUPERFUDS podr&aacute; cancelar la Orden de Compra del Usuario y
          restituir el valor pagado u ofrecer una nueva opci&oacute;n en caso de que se quiera dar
          continuidad a la relaci&oacute;n de servicio. Lo anterior, de acuerdo a las facultades que
          otorga la ley para cada caso.
        </p>

        <p>
          TRIGESIMO PRIMERO. Limitaci&oacute;n de responsabilidad. El Usuario acepta que SUPERFUDS
          no se hace responsable por ning&uacute;n da&ntilde;o directo, indirecto, lucro cesante,
          da&ntilde;o emergente, da&ntilde;o incidental, especial o consecuencial, proveniente de o
          en relaci&oacute;n con (i) el uso de la Plataforma; (ii) la responsabilidad de cualquier
          aliado log&iacute;stico que no preste sus servicios bajo subordinaci&oacute;n y
          dependencia de SUPERFUDS; o (iii) en relaci&oacute;n con el rendimiento o
          navegaci&oacute;n en la Plataforma o sus enlaces a otros sitios web, incluso si SUPERFUDS
          ha sido informado de la posibilidad de tales da&ntilde;os. El Usuario reconoce y acepta
          que los aliados log&iacute;sticos encargados de la entrega de las Ordenes de Compra son
          empresas y comercios dedicados a dicha actividad; la relaci&oacute;n entre SUPERFUDS y el
          Usuario corresponde a garantizar mediante los medios tecnol&oacute;gicos y de servicio con
          los que cuenta, el facilitar al Usuario un cat&aacute;logo de Productos ofrecidos para la
          respectiva compra y el traslado de &eacute;stos al destino indicado por el Usuario.
          SUPERFUDS no cuenta con servicio de transporte directo ni personal dedicado a tal fin; en
          este sentido, se configura la tercerizaci&oacute;n de un servicio de transporte de los
          Productos que solicita el Usuario.
        </p>

        <p>
          Adem&aacute;s, el Usuario acepta que SUPERFUDS no se hace responsable de los da&ntilde;os
          derivados de la interrupci&oacute;n, suspensi&oacute;n o terminaci&oacute;n de los
          Servicios, incluyendo sin limitaci&oacute;n da&ntilde;o directo, indirecto, lucro cesante,
          da&ntilde;o emergente, da&ntilde;o incidental, especial o consecuencial, aun cuando dicha
          interrupci&oacute;n, suspensi&oacute;n o terminaci&oacute;n estuviera o no justificada. En
          ning&uacute;n caso la responsabilidad total de SUPERFUDS ante el Usuario por cualquier
          clase de p&eacute;rdidas podr&aacute; exceder los montos pagados por &eacute;ste a
          SUPERFUDS.
        </p>

        <p>
          TRIG&Eacute;SIMO SEGUNDO. Domicilio contractual y legislaci&oacute;n aplicable. Los
          presentes T&eacute;rminos y Condiciones de Uso de la Plataforma se acogen en el territorio
          Colombiano, conforme a su normatividad general y sectorial. Su adopci&oacute;n implica el
          ejercicio de su libre voluntad y que la relaci&oacute;n que surge de este documento se
          regir&aacute; en todos sus efectos por su contenido y en su defecto por la ley comercial
          colombiana.
        </p>

        <p>
          TRIG&Eacute;SIMO TERCERO. Aceptaci&oacute;n de los t&eacute;rminos y condiciones. El
          Usuario manifiesta expresamente tener capacidad legal para usar la Plataforma y para
          celebrar las transacciones comerciales que se puedan generar con SUPERFUDS. As&iacute;
          mismo, manifiesta haber suministrado informaci&oacute;n real, veraz y fidedigna; por ende
          de forma expresa e inequ&iacute;voca declara que ha le&iacute;do, que entiende y que
          acepta la totalidad de las situaciones reguladas en el presente escrito de T&eacute;rminos
          y Condiciones de Uso de la Plataforma, por lo que se compromete al cumplimiento total de
          los deberes, obligaciones, acciones y omisiones aqu&iacute; expresadas. En caso que
          Usuarios de otros pa&iacute;ses utilicen la Plataforma para solicitar productos y
          servicios en Colombia se sujetan completamente a lo dispuesto en los presentes
          t&eacute;rminos.
        </p>

        <p>
          TRIG&Eacute;SIMO CUARTO. Soporte. Los siguientes son los canales habilitados para el
          servicio de atenci&oacute;n al cliente en donde los Usuarios se podr&aacute;n comunicar
          ante cualquier duda o ayuda con pedidos o devoluciones. Correo:
          <a href="mailto:experiencia@superfuds.com.co">experiencia@superfuds.com.co </a>
          &nbsp;whastapp +57 322 683 2493
        </p>
        <p>
          <strong>Datos de notificaci&oacute;n judicial:&nbsp;</strong> SUPERFUDS S.A.S. &nbsp; Nit.
          900.703.907-7
        </p>
        <p>
          <strong>Direcci&oacute;n de notificaci&oacute;n judicial:</strong> CL 18 A CR 69 F 56
        </p>
        <p>
          <strong>Correo electr&oacute;nico:</strong> contabilidad@superfuds.com.co
        </p>
        <p>
          <strong>Tel&eacute;fono de notificaci&oacute;n judicial:</strong> +57 3206481094
        </p>
      </section>
    </Grid>
  </Layout>
);

export { TermsandConditionsContainer };
