import React from 'react';
import PropTypes from 'prop-types';
import WAImage from '@assets/svg/whatsapp.icon.svg';

import './styles.scss';

const CustomerServiceButton = ({ user }) => {
  return (
    <div id="sirena_button" className="right title" role="presentation">
      <a
        id="floating_button_sirena"
        className="widget_bg1 right"
        href="https://wa.link/bar9rx"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <img src={WAImage} id="widget_logo" alt="Whatsapp Logo" />
      </a>
    </div>
  );
};

CustomerServiceButton.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

export { CustomerServiceButton };
